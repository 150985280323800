import axios from "axios";

const apiInstance = axios.create({
  baseURL: "http://127.0.0.1:8000",
});

const getNews = async (language) => {
  let result;
  try {
    const response = await apiInstance.get(`/api/siteNewsView?language=${language}`);
    result = response.data;
  } catch (error) {
    console.error("Error fetching news:", error);
    result = error;
  }
  return result;
};

const NewsService = {
  getNews,
};

export default NewsService;
