import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"
import { connect } from "react-redux"
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Table } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
const LoadingContainer = () => <div>Loading...</div>

const GetInTouch = props => {
  //meta title
  document.title = "PDPS"

  const [status, setStatus] = useState("")
  useEffect(() => {
    const now = new Date()
    const currentDay = now.getDay() // 0 (Sunday) to 6 (Saturday)
    const currentHour = now.getHours()
    const currentMinutes = now.getMinutes()

    if (currentDay >= 1 && currentDay <= 5) {
      // Monday to Friday
      if (
        (currentHour === 8 && currentMinutes >= 30) ||
        (currentHour > 8 && currentHour < 16) ||
        (currentHour === 16 && currentMinutes <= 30)
      ) {
        setStatus("Open")
      } else {
        //setStatus(props.t("Closed"))
        setStatus("Closed")

      }
    } else {
      //setStatus(props.t('Closed'))
      setStatus("Closed")
    }
  }, [])

  const selectedPlace = {}
  const phoneNumber = "0812476276";

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Contact us")}
            breadcrumbItem={props.t("Get in touch")}
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle> {props.t("Contact Information")} </CardTitle>
                  <div className="table-responsive">
                    <Table className="table table-hover mb-0 contactUs-page-table">
                      <tbody>
                        <tr>
                          <th scope="row">
                            <i className="mdi mdi-phone-classic me-2 contactUs-page-icons"></i>
                          </th>
                          <th scope="row">{props.t("Telephone")}</th>
                          <td>
                              <a href={`tel:${phoneNumber}`} className="phone-link"> {phoneNumber} </a>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <i className="mdi mdi-fax me-2 contactUs-page-icons"></i>
                          </th>
                          <th scope="row">{props.t("Fax")}</th>
                          <td> 0812476276 </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <i className="far fa-address-card me-2 contactUs-page-icons"></i>
                          </th>
                          <th scope="row">{props.t("Postal Address")}</th>
                          <td> {props.t("Postal Address data")}</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <i className="bx bx-envelope me-2 contactUs-page-icons"></i>
                          </th>
                          <th scope="row">{props.t("Email")}</th>
                          <td> pathadumbaraps@gmail.com </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <i className="bx bx-time-five me-2 contactUs-page-icons"></i>
                          </th>
                          <th scope="row">{props.t("Office time")}</th>
                          <td>
                            {" "}
                            {props.t("Office time data")}.
                              [{status === "Open" ? (
                                <span style={{ color: "blue" }}> {props.t("Opened")} </span>
                              ) : (
                                <span style={{ color: "red" }}> {props.t("Closed")} </span>
                              )}]
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle> {props.t("Map")} </CardTitle>

                  <div
                    id="gmaps-overlay"
                    className="gmaps"
                    style={{ position: "relative" }}
                  >
                    <Map
                      google={props.google}
                      zoom={14}
                      style={{ width: "100%", height: "100%" }}
                      initialCenter={{
                        lat: 7.333806921771056,
                        lng: 80.67426359130208,
                      }}
                    >
                      <Marker
                        title={"Pathadumbara Pradeshiya Sabha"}
                        name={"Pathadumbara Pradeshiya Sabha"}
                        position={{
                          lat: 7.333907022206054,
                          lng: 80.67429309154137,
                        }}
                      />

                      <InfoWindow>
                        <div>
                          <h1>{selectedPlace.name}</h1>
                        </div>
                      </InfoWindow>
                    </Map>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

GetInTouch.propTypes = {
  google: PropTypes.object
}



export default withTranslation()(
  connect(null, {})(GoogleApiWrapper({
    apiKey: "AIzaSyCaBBtYEAuiajuy1CzL05YIuB4CGx_VQ6s",
    LoadingContainer: LoadingContainer,
    v: "3",
  })(GetInTouch))
);
