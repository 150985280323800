import React, { useEffect, useState, useMemo } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"

import { Card, CardBody, Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import { getCustomers as onGetCustomers } from "store/Test/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../components/Common/TableContainer"

// Column
import {
  id,
  //profile_pic,
  full_name,
  tel1,
  tel2,
  division,
  position,
} from "./EcommerceCustCol"

const EcommerceCustomers = props => {
  //meta title
  document.title = "PDPS"

  const dispatch = useDispatch()

  const { customers } = useSelector(state => ({
    customers: state.ecommerce.customers,
  }))


  const [customerList, setCustomerList] = useState([])


  // Customber Column
  const columns = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return <id {...cellProps} />
        },
      },
      {
        Header: "full_name",
        accessor: "full_name",
        disableFilters: true,
        Cell: cellProps => {
          return <full_name {...cellProps} />
        },
      },
      {
        Header: "position",
        accessor: "position",
        disableFilters: true,
        Cell: cellProps => {
          return <position {...cellProps} />
        },
      },
      {
        Header: "division",
        accessor: "division",
        disableFilters: true,
        Cell: cellProps => {
          return <division {...cellProps} />
        },
      },
      {
        Header: "tel1",
        accessor: "tel1",
        disableFilters: true,
        Cell: cellProps => {
          return <tel1 {...cellProps} />
        },
      },

    ],
    []
  )

  useEffect(() => {
    if (customers && !customers.length) {
      dispatch(onGetCustomers())
    }
  }, [dispatch, customers])

  useEffect(() => {
    setCustomerList(customers)
  }, [customers])

  useEffect(() => {
    if (!isEmpty(customers)) {
      setCustomerList(customers)
    }
  }, [customers])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Customers" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={customers}
                    isGlobalFilter={true}
                    customPageSize={2}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EcommerceCustomers.propTypes = {
  customers: PropTypes.array,
  onGetCustomers: PropTypes.func,
}


export default EcommerceCustomers
