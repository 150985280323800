import React, { useEffect, useState, useRef, useMemo } from "react"
import { withRouter, Link } from "react-router-dom"
import TableContainer from "../../components/Common/TableContainer"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  FormGroup,
  Button, CardTitle
} from "reactstrap";
import * as Yup from "yup"
import { useFormik } from "formik"
import { withTranslation } from "react-i18next"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//redux
import { useSelector, useDispatch } from "react-redux"
//import ComplainDetailsModal from "./ComplainDetailsModal";

const TranslatedTableContainer = withTranslation()(TableContainer);
const CommitteeReport = props => {

  //meta title
  document.title = "PDPS"
  //View popup window
  const [modal1, setModal1] = useState(false);


  const dispatch = useDispatch()

  // const TranslatedTableContainer = withTranslation()(TableContainer);



  const [modal, setModal] = useState(false)



  const columns = useMemo(

    () => [
      {
        Header: "#",
        accessor: "id",
        disableFilters: true,
      },

      {
        Header: () => <span>{props.t("Applications")}</span>,
        accessor: "topic",
        disableFilters: true,

      },

      {
        Header: () => <span>{props.t("Show")} / {props.t("Download")}</span>,
        accessor: 'view',
        disableFilters: true,
        Cell: () => {
          return (
            <div>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"

              >
                <i className="mdi mdi-book-open-variant d-block font-size-16"></i>{" "}
              </Button>
              &nbsp; | &nbsp;
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"

              >
                <i className="mdi mdi-download d-block font-size-16"></i>{" "}
              </Button>
            </div>
          );
        }
      },


    ],
    [props.t]
  );
  const data = [
    {
      "id": "3",
      "topic": "Water supply",
    },
    {
      "id": "2",
      "topic": "Rent Hall",
    },
    {
      "id": "1",
      "topic": "Trade License",
    },
  ];



  //Pagination
  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }



  return (
    <React.Fragment>

      {/*------------------ Render Breadcrumbs----------------- */}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Downloads")}
            breadcrumbItem={props.t("Commitee Reports")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="service-heading">
                  <CardTitle className="h4 text-lg-center">
                    {props.t("Commitee Reports")}
                  </CardTitle>
                </CardBody>
                <CardBody>
                  {/*-----------------User List Table Start------------------*/}
                  <TranslatedTableContainer
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    // handleComplainClick={handleUserClicks}
                    customPageSize={10}
                    className=""
                    props={props} // Pass props explicitly
                  />
                  {/*-----------------User List Table End------------------*/}


                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/*------------------ Render Breadcrumbs Ends----------------- */}
    </React.Fragment>
  )
}

export default withTranslation()(CommitteeReport)
