import React, { useEffect, useState, useRef, useMemo } from "react"
import { withRouter, Link } from "react-router-dom"
import TableContainer from "../../components/Common/TableContainer"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  FormGroup,
  Button, CardTitle
} from "reactstrap";
import * as Yup from "yup"
import { useFormik } from "formik"
import { withTranslation } from "react-i18next"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//redux
import { useSelector, useDispatch } from "react-redux"
//i18n

const TranslatedTableContainer = withTranslation()(TableContainer);

const OfficerView = props => {

  //meta title
  document.title = "PDPS";
  const dispatch = useDispatch()

   const [modal, setModal] = useState(false)



  const columns = useMemo(

    () => [
      {
        Header: "#",
        accessor: "id",
        disableFilters: true,
      },

      {
        Header: () => <span>{props.t("Officer")}</span>,
        accessor: "officer",
        disableFilters: true,
      },

      {
        Header: () => <span>{props.t("Name")}</span>,
        accessor: "name",
        disableFilters: true,
      },

      {
        Header: () => <span>{props.t("Section")}</span>,
        accessor: "section",
        disableFilters: true,
      },

      {
        Header: () => <span>{props.t("Position")}</span>,
        accessor: "position",
        disableFilters: true,
      },

      {
        Header: () => <span>{props.t("Responsibility")}</span>,
        accessor: "responsibility",
        disableFilters: true,
      },
    ],
    [props.t]
  );
  const data = [
    {
      "id": "36",
      "officer":"",
      "name": props.t("Mrs. D.L.K. Senaratne"),
      "section":props.t("Internal Audit Division"),
      "position":props.t("Internal Audit Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "35",
      "officer":"",
      "name": props.t("Mrs. T.M.M.P. Abeysinghe"),
      "section":props.t("Environment Division"),
      "position":props.t("Development Officer"),
      "responsibility":props.t("Activities of the Environment Section"),
    },
    {
      "id": "34",
      "officer":"",
      "name": props.t("Mrs. K.G.I.R.  Ransamali Menike"),
      "section":props.t("Environment Division"),
      "position":props.t("Development Officer"),
      "responsibility":props.t("Activities of the Environment Section"),
    },
    {
      "id": "33",
      "officer":"",
      "name": props.t("Mr.K.M.S.G.B. Kulasekara Maya"),
      "section":props.t("Environment Division"),
      "position":props.t("Environment Officer"),
      "responsibility":props.t("Issuance of environmental permits, Waste management activities, Educating schools and communities about environmental issues, Launching programs to celebrate National and International Environment Days, Hazardous tree inspection"),
    },
    {
      "id": "32",
      "officer":"",
      "name": props.t("Mrs. A.A.D.  Hansamali"),
      "section":props.t("Physical Planning, Roads and Lands Division"),
      "position":props.t("Development Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "31",
      "officer":"",
      "name": props.t("Mrs. U.K.C.  Seneviratne"),
      "section":props.t("Physical Planning, Roads and Lands Division"),
      "position":props.t("Development Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "30",
      "officer":"",
      "name": props.t("Mrs. R. M. B. K. Ratnayake"),
      "section":props.t("Physical Planning, Roads and Lands Division"),
      "position":props.t("Development Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "29",
      "officer":"",
      "name": props.t("Mrs. A.M.S. Mihirani"),
      "section":props.t("Physical Planning, Roads and Lands Division"),
      "position":props.t("Development Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "28",
      "officer":"",
      "name": props.t("Mrs. M.G.N.M. Mrs. Muthugala"),
      "section":props.t("Physical Planning, Roads and Lands Division"),
      "position":props.t("Development Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "27",
      "officer":"",
      "name": props.t("Mrs. S.C.P.  Chinta Kumari"),
      "section":props.t("Physical Planning, Roads and Lands Division"),
      "position":props.t("Development Officer"),
      "responsibility":props.t("Implementation of development projects"),
    },
    {
      "id": "26",
      "officer":"",
      "name": props.t("Mrs. S.M.P.  Madhushani"),
      "section":props.t("Physical Planning, Roads and Lands Division"),
      "position":props.t("Development Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "25",
      "officer":"",
      "name": props.t("Mr.G.D.R.H.  Perera"),
      "section":props.t("Physical Planning, Roads and Lands Division"),
      "position":props.t("Technical Officer"),
      "responsibility":"",
    },
    {
      "id": "24",
      "officer":"",
      "name": props.t("Mrs. B.G.W.P.  Batugodage"),
      "section":props.t("Physical Planning, Roads and Lands Division"),
      "position":props.t("Technical Officer"),
      "responsibility":"",
    },
    {
      "id": "23",
      "officer":"",
      "name": props.t("Mrs. S. P.  Kuruppu"),
      "section":props.t("Physical Planning, Roads and Lands Division"),
      "position":props.t("Technical Officer"),
      "responsibility":"",
    },
    {
      "id": "22",
      "officer":"",
      "name": props.t("Mrs. S. P.  Kuruppu"),
      "section":props.t("Revenue Division"),
      "position":props.t("Technical Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "21",
      "officer":"",
      "name": props.t("Mr.K.A.D.T.  Kuruppuarachchi"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "20",
      "officer":"",
      "name": props.t("Mr.B.K.G.D.B.  Dissanayake"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "19",
      "officer":"",
      "name": props.t("Mrs. W.J.M.C.K.K.  Ilangakone"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "18",
      "officer":"",
      "name": props.t("Mrs. N.W.M.A.W.H.K.  Weerasekera"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "17",
      "officer":"",
      "name": props.t("Mrs. N.H.  Rajapaksa"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "16",
      "officer":"",
      "name": props.t("Mrs. A.I. Satsara"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "15",
      "officer":"",
      "name": props.t("Mrs. P.G.R.S.  Gunasekara"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "14",
      "officer":"",
      "name": props.t("Mrs. B.M.S.  Kulatunga"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },


    {
      "id": "13",
      "officer":"",
      "name": props.t("Mrs. D.S.M.D.M.K.G.N.  Subhashini"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "12",
      "officer":"",
      "name": props.t("Mrs. N.S.K. Senarath"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "11",
      "officer":"",
      "name": props.t("Mrs. K.M.S.G.  Kalugalla"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "10",
      "officer":"",
      "name": props.t("Mrs. K.A.S.A. Kurukulasuriya"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "9",
      "officer":"",
      "name": props.t("Mrs. P.H.G.P.D.  Herath"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "8",
      "officer":"",
      "name": props.t("Mrs. U.A.D.C. Sandamali"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "7",
      "officer":"",
      "name": props.t("Mrs. A. R. F. Risana"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "6",
      "officer":"",
      "name": props.t("Mrs. N. Darshani"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "5",
      "officer":"",
      "name": props.t("Mrs. H.M.M.S.G.  Tennakoon"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "4",
      "officer":"",
      "name": props.t("Mrs. I.S.K.  Kapugewatta"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "3",
      "officer":"",
      "name": props.t("Mr.S.M.  Sahadilan"),
      "section":props.t("Institutional Division"),
      "position":props.t("Management Services Officer"),
      "responsibility":props.t("Supply of goods and services"),
    },
    {
      "id": "2",
      "officer":"",
      "name": props.t("Mrs. S.G.M.S. Upatissa"),
      "section":"",
      "position":props.t("Chief Management Services Officer"),
      "responsibility":"",
    },
    {
      "id": "1",
      "officer":"",
      "name": props.t("Mrs. P.H. Dharmarathna"),
      "section":"",
      "position":props.t("Secretary"),
      "responsibility":"",
    },
  ];



  //Pagination
  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("About Us")} breadcrumbItem={props.t("Officers")} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="service-heading">
                  <CardTitle className="h4 text-lg-center">
                    {props.t("Officers")}
                  </CardTitle>
                </CardBody>
                <CardBody>
                  {/*-----------------User List Table Start------------------*/}
                  <TranslatedTableContainer
                    columns={columns}
                    data={data}
                    isGlobalFilter={false}
                    // handleComplainClick={handleUserClicks}
                    customPageSize={10}
                    pagination={{ onPageChange: onPaginationPageChange }}
                    className=""
                    props={props} // Pass props explicitly
                  />
                  {/*-----------------User List Table End------------------*/}


                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(OfficerView);


