import React, { useState, useEffect } from "react"
import { Link, withRouter } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Table,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"

import male from "../../assets/images/members/avatar.jpg"
const Member = props => {
  //meta title
  document.title = "PDPS"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={props.t("About Us")}
            breadcrumbItem={props.t("Members")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="service-heading">
                  <CardTitle className="h4 text-lg-center">
                    {props.t("Members")}
                  </CardTitle>
                </CardBody>
                <CardBody>
                  <Card className="overflow-hidden">
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>{props.t("#")}</th>
                            <th>{props.t("Members")}</th>
                            <th>{props.t("Name")}</th>
                            <th>{props.t("Position")}</th>
                            <th>{props.t("Area")}</th>
                            <th>{props.t("Telephone Numbers")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td> 1. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. T.M. Asanka Kirti Bandara")}{" "}
                            </td>
                            <td> {props.t("Honorable Chairman")} </td>
                            <td> {props.t("Leemagahadeniya")} </td>
                            <td> 071-4446767 /076-5466767 </td>
                          </tr>
                          <tr>
                            <td> 2. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. W.M.S.B. Welagedara")}{" "}
                            </td>
                            <td> {props.t("Honorable Vice President")} </td>
                            <td> {props.t("Aluthgama")} </td>
                            <td> 071-5313266 </td>
                          </tr>
                          <tr>
                            <td> 3. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. D.H.M. Jayantha Indunil Herath")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Aluthgama")} </td>
                            <td> 071-5935231 </td>
                          </tr>
                          <tr>
                            <td> 4. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. M.M Wasantha Kumara Manthilaka")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Doragamuwa")} </td>
                            <td> 077-6171644 </td>
                          </tr>
                          <tr>
                            <td> 5. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. M.A Gamini Arunakirthi")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Yatawara")} </td>
                            <td> 077-9899103 </td>
                          </tr>
                          <tr>
                            <td> 6. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. M.D Janaka Manatunga")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Gunnembilla Eeriyagastenna")} </td>
                            <td> 077-9544545 </td>
                          </tr>
                          <tr>
                            <td> 7. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. G.G Cyril Parakrama")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Meegammana")} </td>
                            <td> 072-5156376 </td>
                          </tr>
                          <tr>
                            <td> 8. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mrs. A.G. Sirima Alakumbura")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Udatalavinna")} </td>
                            <td> 071-2712929 </td>
                          </tr>
                          <tr>
                            <td> 9. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. U.M. Uday Sampath Bandara")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Palletalavinna")} </td>
                            <td> 077-8383044 </td>
                          </tr>
                          <tr>
                            <td> 10. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. B.A.M. Wasantha Kumara Bopegedara")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Paranagama")} </td>
                            <td> 071-8007210/077-6463205 </td>
                          </tr>
                          <tr>
                            <td> 11. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. H.M. Navaratne Bandara")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Abatenna")} </td>
                            <td> 078-3118760 </td>
                          </tr>
                          <tr>
                            <td> 12. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. K.N.N. Prasanna Samarasinghe")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Kahalla")} </td>
                            <td> 077-7173629 </td>
                          </tr>
                          <tr>
                            <td> 13. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mrs. W. Kusumalatha")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Polgolla Balanagala")} </td>
                            <td> 077-3708698 </td>
                          </tr>
                          <tr>
                            <td> 14. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mrs. D.T. Dilrukshi Ambepitiya")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Meegammanawatta")} </td>
                            <td> 072-4120858 </td>
                          </tr>
                          <tr>
                            <td> 15. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. P.G.H.M. Udeshka Chulanga Bandara")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Pihilladeniya")} </td>
                            <td> 071-0330748 </td>
                          </tr>
                          <tr>
                            <td> 16. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. R.M. Lalit Bandara")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Madawala")} </td>
                            <td> 077-9022325/071-9874203 </td>
                          </tr>
                          <tr>
                            <td> 17. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. P.G. Jayasena")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Megodagama")} </td>
                            <td> 077-1151353 </td>
                          </tr>
                          <tr>
                            <td> 18. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. A.G. Wijetunga")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Abatenna")} </td>
                            <td> 077-1798322 </td>
                          </tr>
                          <tr>
                            <td> 19. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. J. Muhammad Naufar")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Udatalavinna Madige")} </td>
                            <td> 077-9060793 </td>
                          </tr>
                          <tr>
                            <td> 20. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. E.M. Atula Banadara Ekanayake")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Meegammanawatta")} </td>
                            <td> 075-7002755/077-9354558/071-1785721 </td>
                          </tr>
                          <tr>
                            <td> 21. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. Abdul Aziz Mohammad Firdaus")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Madawala Madige")} </td>
                            <td> 077-3644183 </td>
                          </tr>
                          <tr>
                            <td> 22. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. P.G. Samarakoon")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Kahalla")} </td>
                            <td> 071-8417491 </td>
                          </tr>
                          <tr>
                            <td> 23. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. M.H. Mohammad Mubarak")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Udatalavinna Madige")} </td>
                            <td> 077-3293858 </td>
                          </tr>
                          <tr>
                            <td> 24. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. D.G.M.M. Mohammad Riyaj")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Pathadumbara Madawala Madige")} </td>
                            <td> 077-071949 </td>
                          </tr>
                          <tr>
                            <td> 25. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mrs. R.M. Nandana Menike")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Doragamuwa")} </td>
                            <td> 076-7827834 </td>
                          </tr>
                          <tr>
                            <td> 26. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mrs. E.M. Rasika Priyadarshini Rambukwella")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Madawala")} </td>
                            <td> 075-6539276 </td>
                          </tr>
                          <tr>
                            <td> 27. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mrs. M.G.K. Kajanga Ranasinghe")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Meegammana")} </td>
                            <td> 072-9936025 </td>
                          </tr>
                          <tr>
                            <td> 28. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. D.K.G. Abusali Ashraf Abubakkar")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Madawala Madige")} </td>
                            <td> 077-3111115/ 0753111115 </td>
                          </tr>
                          <tr>
                            <td> 29. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mrs. W.M. Nandana Menike Valisundara")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Pihilladeniya")} </td>
                            <td> 076-3448143 </td>
                          </tr>
                          <tr>
                            <td> 30. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. Sujeewa Chandana Imbulewagura")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Doragamuwa")} </td>
                            <td> 071-2594590 </td>
                          </tr>
                          <tr>
                            <td> 31. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. H.H.M. Jayaratne")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Abatenna")} </td>
                            <td> 077-3333740 </td>
                          </tr>
                          <tr>
                            <td> 32. </td>
                            <td>
                              <img
                                src={male}
                                alt={""}
                                style={{ width: "60px", height: "70px" }}
                              />
                            </td>
                            <td>
                              {" "}
                              {props.t("Mr. H.M. Azmi")}{" "}
                            </td>
                            <td> {props.t("Honorable Pradeshiya Sabha Member")} </td>
                            <td> {props.t("Madawala Madige")} </td>
                            <td> 0777935077 </td>
                          </tr>

                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Member)
