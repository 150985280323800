import { get, post } from "../helpers/api_helper"
import axios from "axios"

const apiInstance = axios.create({
  baseURL: "http://127.0.0.1:8000",
  withCredentials: true,
});

const getAllcomplain = async () => {
  let responseData
  await localStorage.getItem("auth-token")
  const response = await axios.get("http://127.0.0.1:8000/sanctum/csrf-cookie").then(async res => {
    await axios
      .get("http://127.0.0.1:8000/api/addComplain", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
        },
      })
      .then(resp => {
        // console.log(resp, "response")
        responseData = resp
      })
  })
  return responseData
}

const addComplain = async (data) => {
  const authToken = localStorage.getItem("auth-token");
  console.log("Data being sent:", data); // Log the data sent to the backend
  try {
    await apiInstance.get("/sanctum/csrf-cookie");
    const response = await apiInstance.post("/api/complains", data, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return { result: response.data, errorMessage: '' };
  } catch (error) {
    let errorMessage = 'An error occurred while adding complain action';
    if (error.response && error.response.data && error.response.data.errors) {
      const validationErrors = error.response.data.errors;
      errorMessage = Object.values(validationErrors).join('\n');
    }
    return { result: null, errorMessage };
  }
};

const getSanctum = () => get("http://127.0.0.1:8000/sanctum/csrf-cookie")
const ComplainService = {
  getAllcomplain,
  addComplain,
  getSanctum,

}

export default ComplainService
