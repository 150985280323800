import React, { useState, useEffect } from "react"
import { Link, withRouter } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Col,
  Container,
  Input,
  Label,
  Table,
  Row,
  TabContent,
  TabPane,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  FormFeedback,
  Form,
  Dropdown,
} from "reactstrap"
import Select from "react-select"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import Swal from "sweetalert2"
import ComplainService, {
  addWaterSupplyApply as applyWaterSupply,
} from "../../services/ComplainService"
import ImageUploading from "react-images-uploading"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"

const Complain = props => {
  const { t } = props

  //meta title
  document.title = "PDPS"

  //-------------Image uploader--------------------------------
  const [images, setImages] = React.useState([])
  const maxNumber = 3

  const onChange = (imageList, addUpdateIndex) => {
    // console.log("Selected Images:", imageList);
    // console.log(imageList, addUpdateIndex)
    setImages(imageList)

    // Update Formik's imageList field with the new images
    validation.setFieldValue("imageList", imageList)
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      cname: "",
      tele: "",
      complain: "",
      imageList: [],
    },
    validationSchema: Yup.object({
      tele: Yup.string().matches(
        /^[0-9]{10}$/,
        t("Incorrect Telephone") // Use `t` directly here
      ),
      complain: Yup.string().required(t("Complain required")),
      imageList: Yup.array().of(
        Yup.object().shape({
          file: Yup.mixed()
            .test("fileSize", t("File too large"), value =>
              value ? value.size <= 10 * 1024 * 1024 : true
            )
            .test(
              "fileFormat",
              t("Unsupported Format"), // Translated message for format
              value => (value ? value.type === "image/jpeg" : true)
            ),
        })
      ),
    }),
    onSubmit: values => {
      // Create a FormData object to handle file uploads
      const formData = new FormData()
      formData.append("cname", values.cname)
      formData.append("tele", values.tele)
      formData.append("complain", values.complain)

      // Append each image file to the FormData
      values.imageList.forEach((image, index) => {
        if (image.file) {
          // Ensure 'file' exists
          formData.append(`imageList[${index}]`, image.file) // Append file object
        }
      })
      console.log("Data being sent:", formData) // Debug log to inspect FormData

      // Send the FormData via ComplainService
      ComplainService.addComplain(formData)
        .then(async response => {
          if (response) {
            // Show success alert on successful response
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: props.t("Complain Success"),
            })
            validation.resetForm();  // Reset form fields to initial values
            setImages([]);  // Clear the images array
          }
        })
        .catch(e => {
          // Show error alert if there's an issue
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: props.t("Complain Error"),
          })
        })
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Contact us")}
            breadcrumbItem={props.t("Complains")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="service-heading">
                  <CardTitle className="h4 text-lg-center">
                    {props.t("Complains")}
                  </CardTitle>
                </CardBody>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    encType="multipart/form-data"
                  >
                    <div className="mb-3">
                      <Label className="form-label">
                        {props.t("Your name")} ({props.t("Optional")}):
                      </Label>
                      <Input
                        name="cname"
                        placeholder={props.t("Type your name")}
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.cname || ""}
                        invalid={
                          validation.touched.cname && validation.errors.cname
                            ? true
                            : false
                        }
                      />
                      {validation.touched.cname && validation.errors.cname ? (
                        <FormFeedback type="invalid">
                          {validation.errors.cname}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        {props.t("Telephone")}
                        {props.t("Number")} ({props.t("Optional")}):
                      </Label>
                      <Input
                        name="tele"
                        placeholder={props.t("Type your Telephone number")}
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.tele || ""}
                        invalid={
                          validation.touched.tele && validation.errors.tele
                            ? true
                            : false
                        }
                      />
                      {validation.touched.tele && validation.errors.tele ? (
                        <FormFeedback type="invalid">
                          {validation.errors.tele}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        {props.t("Your complain")} :
                      </Label>
                      <Input
                        name="complain"
                        placeholder={props.t("Type your complain")}
                        type="textarea"
                        rows="4"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.complain || ""}
                        invalid={
                          validation.touched.complain &&
                          validation.errors.complain
                            ? true
                            : false
                        }
                      />
                      {validation.touched.complain &&
                      validation.errors.complain ? (
                        <FormFeedback type="invalid">
                          {validation.errors.complain}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        {props.t("Photographic evidence")} :
                      </Label>
                      <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          <div className="mb-3">
                            <div className="image-upload-button">
                              <button
                                onClick={onImageUpload}
                                {...dragProps}
                                type="button"
                                className="btn btn-outline-secondary w-sm"
                              >
                                <i className="mdi mdi-upload d-block font-size-16"></i>
                                {props.t("Select")}
                              </button>
                              &nbsp;
                              <button
                                type="button"
                                className="btn btn-outline-danger w-sm"
                                onClick={onImageRemoveAll}
                              >
                                <i className="mdi mdi-trash-can d-block font-size-16"></i>
                                {props.t("Remove all")}
                              </button>
                            </div>

                            <div className="image-group">
                              {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <img
                                    src={image["data_url"]}
                                    alt=""
                                    width="250"
                                    height="220"
                                  />
                                  <div>
                                    <button
                                      className="image-close-update-button"
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      <i className="mdi mdi-image-edit"></i>
                                    </button>
                                    <button
                                      className="image-close-update-button"
                                      onClick={() => onImageRemove(index)}
                                    >
                                      <i className="mdi mdi-close image-close-button"></i>
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>

                            {/* Validation error messages for imageList */}
                            {validation.touched.imageList &&
                            validation.errors.imageList ? (
                              <div className="text-danger">
                                {validation.errors.imageList.map(
                                  (error, index) => (
                                    <div key={index}>{error.file}</div> // Ensure correct error display
                                  )
                                )}
                              </div>
                            ) : null}
                          </div>
                        )}
                      </ImageUploading>
                    </div>

                    <div className="text-end d-flex gap-3">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        {" "}
                        {props.t("Submit")}{" "}
                      </button>
                      <button type="reset" className="btn btn-secondary">
                        {" "}
                        {props.t("Cancel")}{" "}
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/*------------------ Render Breadcrumbs Ends----------------- */}
    </React.Fragment>
  )
}

export default withTranslation()(Complain)
