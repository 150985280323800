import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
} from "reactstrap";

//Owl carousel
import OwlCarousel from "react-owl-carousel"
import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next"

const Gallery = props => {

  //meta title
  document.title = "PDPS";


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Gallery")} breadcrumbItem={props.t("Main Page")} />

          <Row>
            <Col xs="12">

              <div className='container-fluid' >

                <OwlCarousel items={3}

                className="owl-theme"

                loop

                nav

                margin={8} >

                <div ><img  className="img" src= {'../../assets/images/gallery/staff/DSC_4144.jpg'} alt={"Staff Photo 1"}/></div>

                  <div ><img  className="img" src= {'../../assets/images/gallery/staff/DSC_4144.jpg'} alt={"Staff Photo 2"}/></div>
                  <div ><img  className="img" src= {'../../assets/images/gallery/staff/DSC_4144.jpg'} alt={"Staff Photo 3"}/></div>
                  <div ><img  className="img" src= {'../../assets/images/gallery/staff/DSC_4144.jpg'} alt={"Staff Photo 4"}/></div>



              </OwlCarousel>

      </div>
    </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Gallery);


